body, html {
    color: #ffffff;
    background-color: #000000;
    font-family: 'Roboto',sans-serif;
}

.backgroundSettings {
    background-image: url("../image/beertap.avif");
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.navBrand {
    color: #fd7e14 !important;
    font-weight: 700;
}

.navBrandScroll {
    color: #b02a37;
    font-weight: 700;
}

.navBackground {
    background-color: #2b3035;
}

.topFixedBanner {
    min-height: 10vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topBannerOverlay {
    position: absolute;
    min-height: 8vh;
    width: 100%;
    background: rgb(0,0,0,0.3);
}

.topContent {
    margin-top: 1em;
    font-family: 'Comic Sans MS',sans-serif;
}

.topTitle {
    font-family: 'Comic Sans MS',sans-serif;
    color: #ffffff;
    font-size: 20px;
}

.servicesOverlay {
    width: 100%;
    min-height: 92vh;
    padding: 65px 0 0 0;
    background: rgb(0,0,0,0.9);
    text-align: center;
}

.footerOverlay {
    width: 100%;
    min-height: 8vh;
    margin: 0;
    padding: 0;
    background: rgb(0,0,0,0.9);
    color: #fd7e14;
    text-align: center;
}

.clean-btn {
    background:none;
    border:none;
    color:inherit;
    cursor:pointer;
    font-family:inherit;
    padding:0
}

.lh-05 {line-height: 0.5!important}
.lh-07 {line-height: 0.7!important}

.top-20{top:20%!important}
.top-30{top:30%!important}
.top-35{top:35%!important}
.top-40{top:40%!important}
.w-10{width:10%!important}
.w-20{width:20%!important}
.w-30{width:30%!important}
.w-40{width:40%!important}
.w-60{width:60%!important}
.w-70{width:70%!important}


.birt-table-rounded {
    border-radius: 9px !important;
    overflow: hidden; /* add this */
}

.search-product-box {

}

.search-product-result-list {

}


